













import CargandoModule from "@/store/modules/cargando-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CargaDialog extends Vue {
  created() {}
  private shw: boolean = false;

  public get dialog_shw() {
    return CargandoModule.mostrar;
  }
}
