import { getModule, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { store } from '@/store/store';

@Module({
    namespaced: true,
    name: 'CSSModule',
    store,
    dynamic: true,
})
class CSSModule extends VuexModule {
    public cssimportados:boolean=false;
    @Mutation
    public css_importados() {
        this.cssimportados = true;
    }

}
export default getModule(CSSModule);
